import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { Topic } from '@models/topic';


@Injectable({
  providedIn: 'root'
})
export class TopicService {

  private TOPIC_URL = environment.apiUrl + '/topics';

  constructor(private http: HttpClient) { }

  getTopics() {
    return this.http.get<Topic[]>(this.TOPIC_URL);
  }
}
