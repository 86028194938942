<div class="column" [ngClass]="isLoggedIn ? '' : 'right-signup'">
  <section id="sessionSelect" class="bg-grey">
    <span (click)="goBack()" class="pointer left-arrow">
        <img src="assets/images/angle-left-orange.svg" class="back-arrow">
    </span>
    <div>
      <div class="text-center" *ngIf="!isLoggedIn">
        <h1 class="font-color-teracotta">Select a Date and Time</h1>
      </div>
      <div class="col text-center desktop-hide">
        <img src="assets/images/process-step-four.png" class="img-fluid step-image-padding">
      </div>
      <div class="col text-center mobile-hide">
        <img src="assets/images/progress_step_four.svg" class="img-progress">
      </div>
      <div class="text-center loggedIn-header" *ngIf="isLoggedIn">
        <h1 class="">Select a Date and Time</h1>
        <h2 class="description mt-3 mb-4">
          All times are displayed in your current timezone. Choose a date & time that works best for your schedule.
        </h2>
      </div>
      <ng-template
      #customCellTemplate
      let-day="day"
      let-openDay="openDay"
      let-locale="locale"
      let-tooltipPlacement="tooltipPlacement"
      let-highlightDay="highlightDay"
      let-unhighlightDay="unhighlightDay"
      let-eventClicked="eventClicked"
      let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody"
      let-tooltipDelay="tooltipDelay"
      let-trackByEventId="trackByEventId"
      let-validateDrag="validateDrag">
        <div class="cal-cell-top">
          <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">
            {{day.badgeTotal}}
          </span>
          <span class="cal-day-number">
            {{day.date | calendarDate: 'monthViewDayNumber':locale}}
          </span>
        </div>
        <div class="cal-events mobile-hide" *ngIf="day.events.length > 0">
          <div class="cal-event"
            *ngFor="let event of day.events | slice:0:showAll ? undefined :3; trackBy: trackByEventId"
            [ngStyle]="{ backgroundColor: event.color?.primary }"
            [ngClass]="event?.cssClass">{{event.title}}</div>
        </div>
      </ng-template>
      <div class="col text-a-c mb-3 mt-3">
        <div class="btn-group">
          <span class="btn btn-primary" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" class="pointer">
            <img src="assets/images/arrow-left.svg" class="back-arrow">
          </span>
          <h2>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h2>
          <span class="btn btn-primary" mwlCalendarNextView [view]="view"
            [(viewDate)]="viewDate" class="pointer">
            <img src="assets/images/arrow-right.svg" class="back-arrow">
          </span>
        </div>
      </div>
      <div class="legend legend-mobile desktop-hide">
        <div class="legend-container">
          <div class="legend-tile available">
            &nbsp;
          </div>
          <span>Available</span>
        </div>
        <div class="legend-container">
          <div class="legend-tile booked">
            &nbsp;
          </div>
          <span>Booked</span>
        </div>
        <div class="legend-container">
          <div class="legend-tile unavailable">
            &nbsp;
          </div>
          <span>Unavailable</span>
        </div>
      </div>
      <div [ngSwitch]="view">
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          [cellTemplate]="customCellTemplate"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (dayClicked)="handleEvent('Day Clicked', $event.day.events[0])">
        </mwl-calendar-month-view>
      </div>
      <div class="legend legend-desktop mobile-hide">
        <div class="legend-container">
          <div class="legend-tile available">
            &nbsp;
          </div>
          <span>Available</span>
        </div>
        <div class="legend-container">
          <div class="legend-tile booked">
            &nbsp;
          </div>
          <span>Booked</span>
        </div>
        <div class="legend-container">
          <div class="legend-tile unavailable">
            &nbsp;
          </div>
          <span>Unavailable</span>
        </div>
      </div>
    </div>

    <!--  Select Time Popup  -->
    <div id="confirm-popup" *ngIf="selectModalVisible">
      <div class="confirm-body mt-5">
        <button type="button" class="menu-close close" aria-label="Close" (click)="closeSelectModal()">
          <span aria-hidden="true">
              <img src="assets/images/close_black.svg" class="menu-close-img">
          </span>
        </button>
        <div>
          <div class="row">
              <div class="col text-center mb-3">
                    <h1 class="">Select Prefered Time</h1>
              </div>
          </div>
          <div class="row mb-3">
            <div class="col d-flex align-items-center">
                  <h2 class="font-color-teracotta">{{selectedMonth}} {{selectedDate}}, {{selectedYear}}</h2>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col mb-5">
              <div id="available-days">
                <ng-container *ngFor="let dt of availableDates; let idx = index">
                  <div class="available-day collapsed" data-toggle="collapse" [attr.data-target]="'#day-' + idx"
                      (click)="toggleCollapse($event)">
                    <h5 class="py-2 mb-0 mr-3">
                      <span>{{ dt.date }}</span>
                    </h5>
                    <div class="down-arrow">
                      <img class="down-arrow-icon" src="assets/images/chevron-down-solid-darkgrey.svg">
                    </div>
                    <div class="right-arrow">
                      <img class="right-arrow-icon" src="assets/images/chevron-right-solid-darkgrey.svg">
                    </div>
                  </div>
                  <div [id]="'day-' + idx" class="available-times collapse" data-parent="#available-days">
                    <div class="available-time" *ngFor="let tm of dt.times" (click)="selectedSession = tm.id"
                        [class.selected]="selectedSession === tm.id" [class.noselect]="selectedSession==undefined">
                      <div class="p-3">
                        <span>{{ tm.time }}</span>
                      </div>
                      <div class="btn-confirm" (click)="gotoBooked(tm.id)">
                        <div class="down-arrow">
                          <img class="down-arrow-icon" src="assets/images/chevron-down-solid-grey.svg">
                        </div>
                        <div class="right-arrow">
                          <img class="right-arrow-icon" src="assets/images/chevron-right-solid-grey.svg">
                        </div>
                        <span>CONFIRM</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="validation-error-message" *ngIf="errorMessage">
                  <span>{{errorMessage}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
