import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { TimeZone } from '../models/time-zone';

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {

  private TIMEZONE_URL = environment.apiUrl + '/timeZones';

  constructor(private http: HttpClient, ) { }

  getTimeZones() {
    return this.http
               .get<TimeZone[]>(this.TIMEZONE_URL)
               .pipe(
                 map(timezones => timezones.sort((a, b) => a.utcOffset - b.utcOffset))
               );
  }

  getTimeZoneById(id: string) {
    return this.http
               .get<TimeZone>(this.TIMEZONE_URL + '?id=' + id)
               .pipe(
                 map(timezone => timezone)
               );
  }
}
