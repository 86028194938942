<div class="body-height">
  <section id="forgot-pwd" class="pt-3 bg-grey mb-5"  >
    <form (submit)="doForgotPassword()" [formGroup]="form">
      <div class="container">
        <!--<div class="row desktop-hide">
          <div class="col text-center">
            <a href="/"><img src="assets/images/voce/VoceLogo-White.png" class="logo-login"></a>
          </div>
        </div>-->
        <div class="row input-box">
          <div class="col page-description text-black text-center">
            <p>Please enter your email address and press the submit button to receive an email with instructions on how
              to reset your password.</p>
          </div>
        </div>
        <div class="row input-box">
          <div class="col">
            <div class="input-group input-group-lg mt-5" [ngClass]="{ 'input-group-error': isFieldInvalid('email') }">
<!--              <div class="input-group-prepend">-->
<!--                <span class="input-group-text" id="email">-->
<!--                  <svg width="21px" height="17px">-->
<!--                    <use href="assets/images/icon_email.svg#Email-Icon"></use>-->
<!--                  </svg>-->
<!--                </span>-->
<!--              </div>-->
              <input type="text" class="form-control" name="email" formControlName="email" placeholder="Email"
                aria-label="Username" aria-describedby="basic-addon1">
            </div>
            <div class="validation-error-message" *ngIf="isFieldInvalid('email')">
              <span>Please enter a valid email address</span>
            </div>
          </div>
        </div>
        <div class="row input-box pt-2 pl-4 msg">
          <span *ngIf="success">Password reset link has been sent to your email! <a [routerLink]="['/login']">Back to login</a></span>
        </div>
        <div class="row input-box">
          <div class="col text-center">
            <button type="submit" class="btn btn-primary btn-desktop bg-purple mt-4">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </section>
</div>
