import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingPage } from './pages/landing/landing.component';
import { SignUpPage } from './pages/sign-up/sign-up.component';
import { SelectTopicPage } from './pages/select-topic/select-topic.component';
import { SelectFacultyPage } from './pages/select-faculty/select-faculty.component';
import { SelectSessionPage } from './pages/select-session/select-session.component';
import { BookedPage } from './pages/booked/booked.component';
import { UpcomingSessionsPage } from './pages/upcoming-sessions/upcoming-sessions.component';
import { ConfirmIsiPage } from './pages/confirm-isi/confirm-isi.component';
import { StartSessionPage } from './pages/start-session/start-session.component';
import { SessionActivePage } from './pages/session-active/session-active.component';
import { RatingPage } from './pages/rating/rating.component';
import { ReportPage } from './pages/report-drug-reaction/report-drug-reaction.component';
import { UnsubscribePage } from './pages/unsubscribe/unsubscribe.component';
import { FaqPage } from './pages/faq/faq.component';
import { ContentLibraryPage } from './pages/content-library/content-library.component';
import { LoginPage } from './pages/login/login.component';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.component';
import { NewPasswordPage } from './pages/new-password/new-password.component';

import { SetAvailabilityPage } from './pages/set-availability/set-availability.component';
import { UpcomingSessionsFacultyPage } from './pages/upcoming-sessions-faculty/upcoming-sessions-faculty.component';
import { DeactivateGuard, ActivateGuard } from '@pages/session-active/session-guard';



const routes: Routes = [
  { path: '', component: LandingPage },
  { path: 'login', component: LoginPage },
  { path: 'signup', component: SignUpPage },
  { path: 'select-topic', component: SelectTopicPage },
  { path: 'select-ambassador', component: SelectFacultyPage },
  { path: 'select-session', component: SelectSessionPage },
  { path: 'booked', component: BookedPage },
  { path: 'upcoming-sessions', component: UpcomingSessionsPage },
  { path: 'confirm-isi', component: ConfirmIsiPage },
  { path: 'start-session', component: StartSessionPage },
  { path: 'session-active', component: SessionActivePage,
    canDeactivate: [DeactivateGuard], canActivate: [ActivateGuard] },
  { path: 'rating', component: RatingPage },
  { path: 'report-drug-reaction', component: ReportPage },
  { path: 'unsubscribe', component: UnsubscribePage },
  { path: 'faq', component: FaqPage },
  { path: 'content-library', component: ContentLibraryPage},
  { path: 'forgot-password', component: ForgotPasswordPage },
  { path: 'new-password', component: NewPasswordPage },

  { path: 'set-availability', component: SetAvailabilityPage },
  { path: 'upcoming-sessions-faculty', component: UpcomingSessionsFacultyPage }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
