import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'oamw-content-library',
  templateUrl: './content-library.component.html',
  styleUrls: ['./content-library.component.scss']
})
export class ContentLibraryPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(navigator.platform.includes('Mac')) {
      document.getElementById('contentLibrary').classList.add('mac-lib');
    }
  }

  gotoUpcoming(): void {}
}
