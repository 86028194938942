import { Injectable } from "@angular/core";
import { CanDeactivate, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import { SessionActivePage } from './session-active.component';
import { StateService } from '@api/state.service';

export class DeactivateGuard implements CanDeactivate<SessionActivePage> {

  canDeactivate(component: SessionActivePage) {
    return component.canDeactivate();
  }

}

@Injectable()
export class ActivateGuard implements CanActivate {

  constructor(private state: StateService, private location: Location) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean> | Promise<boolean> | boolean {
    return this.state.activeSession !== undefined;
  }
}
