import { Component, OnInit } from '@angular/core';

import { IsiStateService } from '../../api/isi-state.service';
import { SessionRating } from '@models/session-rating';
import { SessionRatingService } from '@api/session-rating.service';
import { StateService } from '@api/state.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';


@Component({
  selector: 'oamw-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingPage implements OnInit {

  rating = 0;

  constructor(private isiState: IsiStateService,
              private state: StateService,
              private router: Router,
              private ratingsService: SessionRatingService) { }

  ngOnInit(): void {
    this.isiState.showIsi();
    if (!this.state.hasToken()) {
      this.router.navigate(['']);
    } else if (this.state.activeSession === undefined) {
      this.router.navigate(['upcoming-sessions']);
    }
  }

  rate() {
    let data = {
      sessionId: this.state.activeSession,
      participantId: this.state.user.id,
      score: this.rating
    };
    this.ratingsService.rateSession(data).subscribe(success => {
      if (success) {
        this.router.navigate(['/upcoming-sessions']);
        this.state.clearActiveSession();
      }
    })
  }

}
