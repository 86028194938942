import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  connect, createLocalTracks, CreateLocalTrackOptions,
  LocalTrack,
  Room, RemoteParticipant,
  LocalAudioTrack, LocalVideoTrack,
  RemoteAudioTrack, RemoteVideoTrack
} from 'twilio-video';

import { StateService } from './state.service';


@Injectable({
  providedIn: 'root'
})
export class TwilioService {

  private localContainer: ElementRef<HTMLDivElement>;
  private remoteContainer: ElementRef<HTMLDivElement>;
  private localTracks: LocalTrack[];
  private room: Room;

  private videoOptions: CreateLocalTrackOptions = {
    facingMode: { ideal: 'user' },
    frameRate: { ideal: 60 },
    width: { ideal: 4096 },
    height: { ideal: 2160 },
    name: `camera-${new Date().toISOString().replace(/:/g, '-')}`
  };
  private networkLevel$ = new BehaviorSubject<number>(null);
  private networkLevelRemote$ = new BehaviorSubject<number>(null);

  private videoTrackStatus$ = new BehaviorSubject<boolean>(false);
  private videoTrackStatusRemote$ = new BehaviorSubject<boolean>(false);

  private audioTrackStatus$ = new BehaviorSubject<boolean>(true);
  private audioTrackStatusRemote$ = new BehaviorSubject<boolean>(false);

  private sessionStarted$ = new BehaviorSubject<boolean>(false);
  private sessionEnded$ = new BehaviorSubject<boolean>(false);

  private isConnected$ = new BehaviorSubject<boolean>(false);


  constructor(private state: StateService) { }

  get sessionStarted() {
    return this.sessionStarted$.asObservable();
  }

  get sessionEnded() {
    return this.sessionEnded$.asObservable();
  }

  // get volume() {
  //   return this.volume$.asObservable();
  // }

  async initLocalMedia(localContainer: ElementRef<HTMLDivElement>) {
    this.localContainer = localContainer;
    this.localTracks = await createLocalTracks({ audio: true, video: this.videoOptions });
    this.audioTrackStatus$.next(true);

    // Attach local tracks
    this.localTracks
        .filter(track => track.kind !== 'data')
        .forEach((track: LocalAudioTrack | LocalVideoTrack) => {
          const elem = track.attach();
          elem.muted = true;
          this.localContainer.nativeElement.appendChild(elem);

          this.localContainer.nativeElement.classList.add('active');
          this.state.sessionType === 'video' && this.localContainer.nativeElement.classList.remove('no-video');
          this.state.sessionType === 'video' && this.videoTrackStatus$.next(true);

        });
  }

  async disposeLocalMedia() {
    if (this.localTracks) {
      this.localTracks.forEach((track: LocalAudioTrack | LocalVideoTrack) => track.stop());
      this.localContainer.nativeElement.innerHTML = '';
      this.localContainer.nativeElement.classList.remove('active');
      this.localContainer.nativeElement.classList.add('no-video');


      this.localTracks = undefined;
    }
  }

  async joinRoom(remoteContainer: ElementRef<HTMLDivElement>) {
    this.localTracks
        .filter(track => track.kind === 'video')
        .forEach((track: LocalVideoTrack) => this.state.sessionType === 'audio' && track.disable());

    this.remoteContainer = remoteContainer;

    this.room = await connect(this.state.twilioToken, {
      name: this.state.activeSession,
      tracks: this.localTracks,
      preferredVideoCodecs: ['VP8', 'H264'],
      networkQuality: { local: 1, remote: 1 } });
    this.setUpHandlers();

  }

  leaveRoom() {
    this.room?.disconnect();
    this.sessionStarted$.next(false);
  }

  // unpublishOnReload() {
  //   this.room.localParticipant.videoTracks.forEach(publication => {
  //     publication.track.stop();
  //     publication.unpublish();
  //     console.log('User leaving page');
  //   });
  // }

  toggleAudio() {
    this.localTracks
      .filter(track => track.kind === 'audio')
      .forEach((track: LocalAudioTrack) => {
        track.isEnabled ? track.disable() : track.enable();
        this.audioTrackStatus$.next(track.isEnabled);
      });
  }

  toggleVideo() {
    this.localTracks
      .filter(track => track.kind === 'video')
      .forEach((track: LocalVideoTrack) => {
        if (track.isEnabled) {
          track.disable();
          this.localContainer.nativeElement.classList.add('hidden');
          this.localContainer.nativeElement.classList.add('no-video');
        } else {
          track.enable();
          this.localContainer.nativeElement.classList.remove('hidden');
          this.localContainer.nativeElement.classList.remove('no-video');
        }
        this.videoTrackStatus$.next(track.isEnabled);
    });
  }

  get networkLevel() {
    return this.networkLevel$.asObservable();
  }

  get networkLevelRemote() {
    return this.networkLevelRemote$.asObservable();
  }

  get videoTrackStatusRemote() {
    return this.videoTrackStatusRemote$.asObservable();
  }

  get videoTrackStatus() {
    return this.videoTrackStatus$.asObservable();
  }

  get audioTrackStatusRemote() {
    return this.audioTrackStatusRemote$.asObservable();
  }

  get audioTrackStatus() {
    return this.audioTrackStatus$.asObservable();
  }

  get isConnected() {
    return this.isConnected$.asObservable();
  }

  setIsConnected(status: boolean) {
    this.isConnected$.next(status);
  }


  ///

  private setUpHandlers() {
    // Log your Client's LocalParticipant in the Room
    const localParticipant = this.room.localParticipant;
    console.log(`Connected to the Room ${this.room.name} as "${localParticipant.identity}"`);

    localParticipant.on('networkQualityLevelChanged', level => {
      console.log('Local Network Level:', level);
      this.networkLevel$.next(level);
      this.displayNetworkIndicator(this.localContainer.nativeElement, level);
    });

    // Process any Participants already connected to the Room
    this.room.participants.forEach((participant: RemoteParticipant) => {
      console.log(`Participant "${participant.identity}" is already connected to the room`);
      this.processParticipant(participant);
      this.sessionStarted$.next(true);
    });

    localParticipant.on('trackPublicationFailed', (error, localTrack) => {
      console.warn('Failed to publish LocalTrack "%s": %s', localTrack.name, error.message);
    });

    this.room.on('trackSubscriptionFailed', (error, remoteTrackPublication, remoteParticipant) => {
      console.warn('Failed to subscribe to RemoteTrack "%s" from RemoteParticipant "%s": %s"',
      remoteTrackPublication.trackName, remoteParticipant.identity, error.message);
    });

    // Process new Participants as they connect to the Room
    this.room.once('participantConnected', (participant: RemoteParticipant) => {
      console.log(`Participant "${participant.identity}" has connected to the room`);
      this.processParticipant(participant);
      this.sessionStarted$.next(true);
    });

    // Log Participants as they disconnect from the Room
    this.room.once('participantDisconnected', (participant: RemoteParticipant) => {
      console.log(`Participant "${participant.identity}" has disconnected from the room`);
      this.remoteContainer.nativeElement.querySelector(':scope > audio').remove();
      this.remoteContainer.nativeElement.querySelector(':scope > video').remove();
      this.remoteContainer.nativeElement.querySelector(':scope > .network').remove();
      this.remoteContainer.nativeElement.classList.remove('active');
      this.remoteContainer.nativeElement.classList.add('no-video');
      if (this.state.user.type === 'PARTICIPANT') {
        this.sessionEnded$.next(true);
      }
    });

  }

  // handleTrackDisabled(track) {
  //   track.on('disabled', () => {
  //     this.remoteContainer.nativeElement.classList.add('no-video');
  //     this.videoTrackStatusRemote$.next(false);
  //   });
  // }

  private processParticipant(participant: RemoteParticipant) {
    this.remoteContainer.nativeElement.classList.add('active');
    this.displayNetworkIndicator(this.remoteContainer.nativeElement, participant.networkQualityLevel);

    console.log('Remote Network Level:', participant.networkQualityLevel);
    participant.on('networkQualityLevelChanged', level => {
      console.log('Remote Network Level:', level);
      this.displayNetworkIndicator(this.remoteContainer.nativeElement, level);
      this.networkLevelRemote$.next(level);
    });

    participant.tracks.forEach(publication => {
      console.log(`Participant "${participant.identity}" has added ${publication.kind} publication [${publication.trackName}] to room [${publication.track?.isEnabled}]`);

      if (publication.isSubscribed && publication.kind !== 'data') {
        const track = publication.track as RemoteAudioTrack | RemoteVideoTrack;
        this.processTrack(track);
      }
    });

    // Process new tracks as they are added
    participant.on('trackSubscribed', (track: RemoteAudioTrack | RemoteVideoTrack) => {
      console.log(`Participant "${participant.identity}" has added ${track.kind} track [${track.name}] to room [${track.isEnabled}]`);
      this.processTrack(track);
    });
  }

  private processTrack(track: RemoteAudioTrack | RemoteVideoTrack) {
    this.remoteContainer.nativeElement.appendChild(track.attach());
    if (track.kind === 'video') {
      track.isEnabled && this.remoteContainer.nativeElement.classList.remove('no-video');
      this.videoTrackStatusRemote$.next(track.isEnabled);
      track.on('enabled', () => {
        this.remoteContainer.nativeElement.classList.remove('no-video');
        this.videoTrackStatusRemote$.next(true);
      });
      track.on('disabled', () => {
        this.remoteContainer.nativeElement.classList.add('no-video');
        this.videoTrackStatusRemote$.next(false);
      });
    } else if (track.kind === 'audio') {
        this.audioTrackStatusRemote$.next(track.isEnabled);
        track.on('enabled', () => this.audioTrackStatusRemote$.next(true));
        track.on('disabled', () => this.audioTrackStatusRemote$.next(false));
    }
  }

  private displayNetworkIndicator(elem: HTMLDivElement, level: number) {
    const STEP = 0.35;
    let netElem: HTMLDivElement;
    let netElems: NodeListOf<HTMLDivElement> = elem.querySelectorAll(':scope > div.network') /*as HTMLCollectionOf<HTMLDivElement>*/;
    if (netElems.length < 1) {
      netElem = document.createElement('div');
      netElem.classList.add('network');
      elem.appendChild(netElem);
    } else
      netElem = netElems[0];

    netElem.innerHTML = level === null ? '' :
      [1, 2, 3, 4, 5]
        .map(idx => `<div style="height:${STEP * idx}vw;"${level <= idx ? 'class="off"' : ''}></div>`)
        .join('');
  }

}
