import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { Program } from '@models/program';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {


  private PROGRAM_URL = environment.apiUrl + '/programs';

  constructor(private http: HttpClient) { }

  getProgram() {
    return this.http
               .get<Program[]>(this.PROGRAM_URL)
               .pipe(program => program);
  }
}
