import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from '../../environments/environment';

import { handleError, Error } from '@utils/handleError';
import { Candidate, CandidateData } from '@models/candidate';



@Injectable({
  providedIn: 'root'
})
export class CandidatesService {

  private CANDIDATE_URL = environment.apiUrl + '/participants';

  constructor(private http: HttpClient) { }

  addCandidate(data: CandidateData) {
    return this.http
               .post(this.CANDIDATE_URL, data)
               .pipe(
                 map(res => res),
                 catchError(this.handleError())
               );
  }

  getCandidates() {
    return this.http
               .get<Candidate[]>(this.CANDIDATE_URL)
               .pipe(
                 map(questions => questions)
               );
  }

  ///

  handleError() {
    return (err: any) => {
      console.error('ERROR:', err.error.message);
      let error = new Error();
      error.message = err.error.message;
      return of(error);
    };
  }
}
