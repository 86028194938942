import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { handleError } from '@utils/handleError';


@Injectable({ providedIn: 'root' })
export class PixiService {

  private PIXI_URL = environment.apiUrl + '/pixi';


  constructor(private http: HttpClient) { }


  analyzeCaption(appointmentId: string, text: string) {
    return this.http
               .post(this.PIXI_URL + '/analyze', { appointmentId, text })
               .pipe(
                 map(res => res),
                 catchError(handleError())
               );
  }
}
