import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RatingModule } from 'ngx-bootstrap/rating';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NotifierModule } from 'angular-notifier';
import { WebcamModule } from 'ngx-webcam';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LandingPage } from './pages/landing/landing.component';
import { SignUpPage } from './pages/sign-up/sign-up.component';
import { SelectTopicPage } from './pages/select-topic/select-topic.component';
import { SelectFacultyPage } from './pages/select-faculty/select-faculty.component';
import { SelectSessionPage } from './pages/select-session/select-session.component';
import { BookedPage } from './pages/booked/booked.component';
import { UpcomingSessionsPage } from './pages/upcoming-sessions/upcoming-sessions.component';
import { ConfirmIsiPage } from './pages/confirm-isi/confirm-isi.component';
import { StartSessionPage } from './pages/start-session/start-session.component';
import { SessionActivePage } from './pages/session-active/session-active.component';
import { RatingPage } from './pages/rating/rating.component';
import { UnsubscribePage } from './pages/unsubscribe/unsubscribe.component';
import { FaqPage } from './pages/faq/faq.component';
import { ContentLibraryPage } from './pages/content-library/content-library.component';
import { LoginPage } from './pages/login/login.component';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.component';
import { NewPasswordPage } from './pages/new-password/new-password.component';

import { SetAvailabilityPage } from './pages/set-availability/set-availability.component';
import { UpcomingSessionsFacultyPage } from './pages/upcoming-sessions-faculty/upcoming-sessions-faculty.component';
import { AuthInterceptor } from './interceptors/auth-interceptor.service';
import { DeactivateGuard, ActivateGuard } from './pages/session-active/session-guard';
import { environment } from 'src/environments/environment';



@NgModule({
  declarations: [
    AppComponent,
    LandingPage,
    SelectTopicPage,
    SelectFacultyPage,
    SelectSessionPage,
    BookedPage,
    UpcomingSessionsPage,
    ConfirmIsiPage,
    StartSessionPage,
    SessionActivePage,
    RatingPage,
    UnsubscribePage,
    FaqPage,
    LoginPage,
    ForgotPasswordPage,
    NewPasswordPage,
    SignUpPage,
    ContentLibraryPage,
    SetAvailabilityPage,
    UpcomingSessionsFacultyPage,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    RatingModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingID),
    NgxGoogleAnalyticsRouterModule,
    AppRoutingModule,
    WebcamModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NotifierModule.withConfig({
      position: {
        horizontal: {position: 'middle'},
        vertical: {position: 'bottom'}
      },
      behaviour: {
        autoHide: 5000,
        showDismissButton: false,
      },
      animations: {
        enabled: true,
        show: {
            preset: 'slide',
            speed: 300,
            easing: 'ease'
        },
        hide: {
            preset: 'fade',
            speed: 300,
            easing: 'ease',
            offset: 50
        },
        shift: {
            speed: 300,
            easing: 'ease'
        },
        overlap: 150
    }
    }),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  DeactivateGuard,
  ActivateGuard
],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
