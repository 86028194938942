import { of } from 'rxjs';

export function handleError() {
  return (err: any) => {
    console.error('ERROR:', err);

    return of(false);
  }
}

export class Error {
  message: string;
}
