import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Captain } from '@models/captain';


@Injectable({
  providedIn: 'root'
})
export class CaptainsService {

  private CAPTAIN_URL = environment.apiUrl + '/captains';

  constructor(private http: HttpClient) { }

  getCaptains() {
    return this.http
               .get<Captain[]>(this.CAPTAIN_URL)
               .pipe(
                 map(captain => captain)
               );
  }

  getCaptainsByTopic(topicId: string) {
    return this.http
               .get<Captain[]>(this.CAPTAIN_URL + '/available?topicId=' + topicId)
               .pipe(
                 map(captain => captain)
               );
  }
}
