import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { handleError } from '@utils/handleError';
import { SessionRating } from '@models/session-rating';

@Injectable({
  providedIn: 'root'
})
export class SessionRatingService {

  private SESSION_RATING_URL = environment.apiUrl + '/sessionRatings';
  constructor(private http: HttpClient) { }

  rateSession(data: SessionRating) {
    return this.http
               .post(this.SESSION_RATING_URL, data)
               .pipe(
                 map(res => res),
                 catchError(handleError())
               );
  }
}
