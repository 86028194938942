import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment } from '../../environments/environment';

import { Error } from '@utils/handleError';
import { Availability, AvailabilityData } from '@models/captain';


@Injectable({
  providedIn: 'root'
})
export class AvailabilitiesService {

  private AVAILABILITY_URL = environment.apiUrl + '/availabilities';
  private AVAILABILITY_UNUSED_URL = environment.apiUrl + '/availabilities/unused';
  private AVAILABILITY_CAPTAIN_URL = environment.apiUrl + '/availabilities/captain';

  constructor(private http: HttpClient) { }

  addAvailability(data: AvailabilityData) {
    return this.http
               .post(this.AVAILABILITY_URL, data)
               .pipe(
                 map(_ => true),
                 catchError(this.handleError())
               );
  }

  getAvailabilities(captainId: string) {
    return this.http
               .get<Availability[]>(this.AVAILABILITY_UNUSED_URL + '?captainId=' + captainId)
               .pipe(
                 map(availabilities => availabilities)
               );
  }

  getAvailabilitieById(id: string) {
    return this.http
               .get<Availability>(this.AVAILABILITY_URL + '?id=' + id)
               .pipe(
                 map(availability => availability)
               );
  }

  getAvailabilitieByDate(captainId: string, startDate: string, endDate: string) {
    return this.http
               .get<Availability[]>(this.AVAILABILITY_CAPTAIN_URL + '?captainId=' + captainId +
                                                                 '&startDate=' + startDate)
               .pipe(
                 map(availability => availability)
               );
  }

  deleteAvailability(id: string) {
    return this.http
               .delete<Availability[]>(this.AVAILABILITY_URL + '?id=' + id)
               .pipe(
                 map(_ => true),
                 catchError(this.handleError())
               );
  }

  ///

  handleError() {
    return (err: any) => {
      console.error('ERROR:', err.error.message);
      const error = new Error();
      error.message = err.error.message;
      return of(error);
    };
  }
}
