import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { handleError } from '@utils/handleError';
import { ResetPasswrodData } from '@models/account';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {


  private BASE_URL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  unsubscribe(userId: any) {
    return this.http
               .post(this.BASE_URL + '/unsubscribe', userId)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  forgotPassword(email: any) {
    return this.http
               .post(this.BASE_URL + '/forgot-password', email)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }

  resetPassword(data: ResetPasswrodData) {
    return this.http
               .post(this.BASE_URL + '/reset-password', data)
               .pipe(
                 map(_ => true),
                 catchError(handleError())
               );
  }
}
