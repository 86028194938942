import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class IsiStateService {

  private isiVisible$ = new BehaviorSubject<boolean>(true);
  private isiConfirmVisible$ = new BehaviorSubject<boolean>(false);
  private isiConfirmed$ = new BehaviorSubject<boolean>(false);


  get isiVisible() {
    return this.isiVisible$.asObservable();
  }

  get isiConfirmVisible() {
    return this.isiConfirmVisible$.asObservable();
  }

  get isiConfirmed() {
    return this.isiConfirmed$.asObservable();
  }


  showIsi() {
    this.isiVisible$.next(true);
  }

  hideIsi() {
    this.isiVisible$.next(false);
  }

  showIsiConfirm() {
    this.isiConfirmVisible$.next(true);
  }

  hideIsiConfirm() {
    this.isiConfirmVisible$.next(false);
  }

  isiConfirmClick() {
    this.isiConfirmed$.next(true);
  }

}
