import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '@api/state.service';
import { AuthService } from '../../api/auth.service';


@Component({
  selector: 'oamw-booked',
  templateUrl: './booked.component.html',
  styleUrls: ['./booked.component.scss']
})
export class BookedPage {

  isLoggedIn = true;

  constructor(private router: Router,
              private state: StateService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe( status => this.isLoggedIn = status );
  }

  gotoUpcoming() {
    if (!!this.state.selectedTopicByParticipant ||
        !!this.state.selectedCaptainByParticipant) {
          this.state.clearCaptainByParticipant();
          this.state.clearTopicByParticipant();
    }
    this.router.navigate(['/upcoming-sessions']);
    this.authService.updateLoginFlag();
  }

}
