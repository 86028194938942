import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'ngx-custom-validators';
import { PhoneValidators } from 'ngx-phone-validators';

import { AuthService } from '@api/auth.service';
import { TimeZoneService } from '@api/time-zone.service';
import { TimeZone } from '@models/time-zone';
import { Candidate, CandidateData } from '@models/candidate';
import { CandidatesService } from '@api/candidates.service';
import { Error } from '@utils/handleError';
import { StateService } from '@api/state.service';
import { timer } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';


@Component({
  selector: 'oamw-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpPage implements OnInit {

  @ViewChild('selectTrigger')
  selectTrigger: ElementRef<HTMLDivElement>;

  @ViewChild('termsText' , { static: true })
  termsTextElement: ElementRef<HTMLDivElement>;
  termsObserver: IntersectionObserver;

  selectedTimezone: string;
  selectedTimezoneId: string;
  form: FormGroup;

  termsVisible = false;
  termsRead = false;
  termsAccepted = false;
  submitted = false;
  isProcessing = false;
  timezoneFieldValidState = true;
  isDisabled$ = true;
  enrollmentSource: string;
  errorMessage = '';
  played25 = false;
  played50 = false;
  played75 = false;
  played100 = false;

  timezones: TimeZone[] = [];


  @ViewChild('termsEnd', { static: true })
  termsEnd: ElementRef<HTMLSpanElement>;
  observer: IntersectionObserver;

  // @ViewChild('videoPlayer') videoplayer: ElementRef;
  videoPlayed = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private auth: AuthService,
              private timezoneService: TimeZoneService,
              private state: StateService,
              private candidatesService: CandidatesService,
              private gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    if(navigator.platform.match('Mac') !== null) {
      document.getElementById('register').classList.add('mac-register');
    }

    this.getTimezones();

    let password = new FormControl('', Validators.compose([Validators.required, CustomValidators.rangeLength([5, 25])]));
    let confirmPassword = new FormControl({value: '', disabled: true},
                                           Validators.compose([Validators.required, CustomValidators.equalTo(password)]));
    let emailAddress = new FormControl('', Validators.compose([Validators.required, Validators.email]));

    this.form = this.fb.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      emailAddress,
      phone: ['', Validators.compose([Validators.required, PhoneValidators.isPhoneNumber('US')])],
      password,
      confirmPassword,
      // terms: [''],
    });

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0)
          this.termsRead = true;
      });
    });
    this.observer.observe(this.termsEnd.nativeElement);

    this.termsObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0)
          console.log('Worked');

          this.termsTextElement.nativeElement.scroll({
            top: 100,
            left: 0,
            behavior: 'smooth'
          })

          setTimeout(() => {
            this.termsTextElement.nativeElement.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }, 1000)



          // for (let i = 20; i > 0; i--) {
          //   setTimeout(() => this.termsTextElement.nativeElement.scrollTop = i, 1000)
          // }
      });
    });
    this.termsObserver.observe(this.termsTextElement.nativeElement);

    this.route.queryParams.subscribe(q => {
      this.enrollmentSource = q.c;
    });
    this.state.hasToken() && this.router.navigate(['/upcoming-sessions' + (this.state.user.type === 'CAPTAIN' ? '-faculty' : '')]);
  }

  onKey() {
    this.selectTrigger.nativeElement.click();
  }

  onFocus(evt) {
    console.log(evt)
  }

  /*playVideo() {
    this.videoplayer.nativeElement.play();
    this.videoPlayed = true;
  }*/

  onVideoTimeUpdate(event) {
    const currTime = event.target.currentTime / event.target.duration * 100;
    if (currTime > 99 && !this.played100) {
      this.gaService.event('100', 'Video', 'OAMW-Live-ExplainerVideo.mp4');
      this.played100  = true;
    }
    else if (currTime > 74 && !this.played75) {
      this.gaService.event('75', 'Video', 'OAMW-Live-ExplainerVideo.mp4');
      this.played75  = true;
    }
    else if (currTime > 49 && !this.played50) {
      this.gaService.event('50', 'Video', 'OAMW-Live-ExplainerVideo.mp4');
      this.played50  = true;
    }
    else if (currTime > 24 && !this.played25) {
      this.gaService.event('25', 'Video', 'OAMW-Live-ExplainerVideo.mp4');
      this.played25  = true;
    }
  }

  play(event) {
    this.gaService.event('play', 'Video', 'OAMW-Live-ExplainerVideo.mp4');
  }

  pause(event) {
    const currTime = event.target.currentTime / event.target.duration * 100;
    currTime < 100 && this.gaService.event('pause', 'Video', 'OAMW-Live-ExplainerVideo.mp4');
  }

  selecTimezone(timezone: TimeZone) {
    this.selectedTimezone = timezone.label + '(' + timezone.abbr + ')';
    this.selectedTimezoneId = timezone.id;
    this.isSelectFieldInvalid();
  }

  toggleDropdown(event: MouseEvent | FocusEvent) {
    // debugger;
    const elem = event.target as HTMLElement;
    const selectElem = elem.closest('div.ke-select');

    selectElem.classList.toggle('open');
    this.isSelectFieldInvalid();
  }

  closeDropdown(event: FocusEvent) {
    const elem = event.target as HTMLElement;
    const selectElem = elem.closest('div.ke-select');

    selectElem.classList.remove('open');
    this.isSelectFieldInvalid();
  }

  showTerms() {
    this.termsVisible = true;
    document.body.style.overflow = 'hidden';
  }

  acceptTerms() {
    this.termsVisible = false;
    this.termsAccepted = true;
    this.form.get('terms').setValue(this.termsAccepted);
    document.body.style.overflow = 'inherit';
  }

  closeTerms() {
    this.termsVisible = false;
    this.termsAccepted = false;
    this.form.get('terms').setValue(this.termsAccepted);
    document.body.style.overflow = 'inherit';

  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    if (field === 'password' && control.touched) {
      this.isDisabled = (!control.valid && control.touched);
    }
    return !control.valid && (control.touched || (control.untouched && this.submitted));
  }

  isSelectFieldInvalid() {
    this.timezoneFieldValidState = !!this.selectedTimezoneId;
  }

  set isDisabled(value: boolean) {
    this.isDisabled$ = value;
    if (value) {
     this.form.controls['confirmPassword'].disable();
    } else {
       this.form.controls['confirmPassword'].enable();
     }
   }

  doRegister() {
    this.submitted = true;
    this.errorMessage = '';

    if (this.form.invalid)
      return;
    if (!this.timezoneFieldValidState)
      return;

    this.isProcessing = true;
    const postData: CandidateData = {
      firstName: this.form.value.firstName,
      lastName: this.form.value.lastName,
      emailAddress: this.form.value.emailAddress,
      isActive: true,
      mobileNumber: (this.form.value.phone as string),
      timeZoneId: (this.selectedTimezoneId as string),
      password: this.form.value.password,
      enrollmentSource: this.enrollmentSource
    };

    this.candidatesService.addCandidate(postData)
                          .subscribe(
                            this.handleResponse.bind(this));
  }

  ///

  private getTimezones() {
    this.timezoneService
        .getTimeZones()
        .subscribe(timezones => {
          this.timezones = timezones;
        });
  }

  handleResponse(success: { message: any; emailAddress: string; }) {
    console.log(success);
    if (success instanceof Error) {
      this.errorMessage = "Something went wrong. Please try again!";
    }
    else {
    success && this.auth.silentLogin(success.emailAddress, this.form.value.password)
                        .subscribe(s => {
                          // tslint:disable-next-line: no-unused-expression
                          s && this.auth.getUserDetails()
                                        .subscribe(u => {
                                          u && this.router.navigate(['/select-topic']);
                                        });
                        });
    }
    this.isProcessing = false;
  }

  protected readonly console = console;
}
