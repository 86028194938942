import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { CustomValidators } from 'ngx-custom-validators';

import { AppComponent } from '../../app.component';
import { AccountsService } from '@api/accounts.service';



@Component({
  selector: 'ke-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordPage implements OnInit {

  form: FormGroup;
  token: string;

  submitted = false;


  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private apc: AppComponent,
              private accountsService: AccountsService) { }

  ngOnInit(): void {
    if(navigator.platform.match('Mac') !== null) {
      document.getElementById('login').classList.add('mac-login');
    }
    let password = new FormControl('', Validators.compose([Validators.required, CustomValidators.rangeLength([5, 25])]));
    let confirmPassword = new FormControl('', Validators.compose([Validators.required, CustomValidators.equalTo(password)]));

    this.form = this.fb.group({
      password,
      confirmPassword,
    });

    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });
  }

  isFieldInvalid(field: string) {
    const control = this.form.get(field);
    return !control.valid && (control.touched || (control.untouched && this.submitted));
  }

  doResetPassword() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    const formData = {token: this.token, password: this.form.value.password};
    this.accountsService.resetPassword(formData).subscribe( success => {
        success && this.router.navigate(['/']);
    })

  }

}
