<div class="column">
  <section id="report" class="bg-grey">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <h1>Report Adverse Drug Reaction</h1>
        </div>
      </div>
      <div class="report-container">
        <a href="#" class="report-link">Click here</a> to report adverse drug reaction OR call at +1-800-432-5679
      </div>
    </div>
  </section>
</div>
