import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'oamw-report',
  templateUrl: './report-drug-reaction.component.html',
  styleUrls: ['./report-drug-reaction.component.scss']
})
export class ReportPage implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(navigator.platform.match('Mac') !== null) {
      document.getElementById('report').classList.add('mac-report');
    }

  }

}
