<div class="pt-10p">
  <div id="sessionStart" class="video-box">
    <div id="network-warning" [hidden]="netSpeedAvg > 2">
      <fa-icon [icon]="faExclamationTriangle" size="sm"></fa-icon>
      Your network strength is too low which may cause connection issues
      with your video chat. Consider moving to a better connection.
    </div>

    <section id="startSession">
      <div>
        <div>
          <div class="col video-headline font-color-teracotta text-center mb-4">
            <h1 class="mobile-hide">If you plan to use your video, please make sure it is displaying properly before joining the session.</h1>
          </div>
        </div>
      </div>

      <div id="video-container">
        <div class="webcam-wrapper no-video" id="localVideo">
          <video playsinline #localVideo></video>
        </div>
      </div>
      <h6>Network Speed: {{netSpeedAvg.toFixed(2)}}{{unit}}</h6>
    </section>
    <section class="player-info">
      <div class="row mr-0 ml-0">
        <div class="col">
          <div class="text-danger text-center" *ngIf="showPermissionsError">
            An error occurred while accessing {{ sessionType === 'video' ? 'camera/' : '' }}microphone.
            Please ensure all required permissions are provided.
          </div>
          <div>
            <button class="btn btn-primary btn-desktop my-2" (click)="startSession('video')">Start My Session</button>
            <button class="btn btn-primary btn-desktop my-2" (click)="startSession('audio')">Start My Session with Audio Only</button>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
