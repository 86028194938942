<div class="pt-10p">
  <div class="video-box">
    <div class="body-height">
      <section id="rating" class="pt-3 bg-grey">
        <div class="container">
          <div class="row">
            <div class="col font-color-teracotta text-center mb-4">
              <h1>Please rate the value of this session</h1>
              <p>1 Star = Not helpful,<br> 5 Stars = Very helpful</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="rating-stars mb-5 ml-1">
                <rating [max]="5" [customTemplate]="rt" [(ngModel)]="rating" class="row"></rating>
                <ng-template #rt let-idx="index" let-val="value">
                  <img [src]="'assets/images/star' + (idx < val ? '_selected' : '') + '.svg'" class="col w-20">
                </ng-template>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col text-center">
              <button type="button" class="btn btn-primary btn-desktop bg-purple my-4" (click)="rate()"
                [disabled]="rating < 1">Submit</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
