<div class="column">
    <section id="contentLibrary">
        <div>
            <div class="row">
                <div class="col text-center">
                    <h1>Content Library</h1>
                </div>
            </div>
            <div class="content-container">
                <span class="title font-color-teracotta desktop-hide">
                  Title
                </span>
                <div class="content-image">
                  Content Image
                </div>
                <div class="">
                    <p class="title font-color-teracotta mobile-hide">
                      Title
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <button type="button" class="btn btn-primary bg-purple mt-3" (click)="gotoUpcoming()">Download Now</button>
                </div>
            </div>
            <div class="content-container">
                <span class="title font-color-teracotta desktop-hide">
                  Title
                </span>
                <div class="content-image">
                  Content Image
                </div>
                <div class="">
                    <p class="title font-color-teracotta mobile-hide">
                      Title
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <button type="button" class="btn btn-primary bg-purple mt-3" (click)="gotoUpcoming()">Download Now</button>
                </div>
            </div>
        </div>
    </section>
</div>
