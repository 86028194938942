<div class="body-height">
    <section id="unsubscribe" class="bg-grey" *ngIf="!unsubscribed">
        <div class="container">
            <div class="row">
                <div class="col text-center mb-4">
                    <h1>Are you sure you want to unsubscribe?</h1>
                    <p class="description mt-3">If you unsubscribe, you will no longer be able to participate in the program.</p>
                </div>
            </div>

            <div class="row">
                <div class="col text-center">
                    <button type="button" class="btn btn-primary btn-desktop bg-purple mt-3" (click)="unsubscribe()">Unsubscribe</button>
                </div>
            </div>

            <!-- <div class="row">
        <div class="col text-center">
          <button type="button"
            class="btn btn-secondary btn-light btn-desktop bg-grey text-red mt-2 mb-4"><u>Cancel</u></button>
        </div>
      </div> -->
        </div>
    </section>

    <div class="container py-2 bg-grey" *ngIf="unsubscribed"></div>

    <section id="unsubscribed" class="pt-3 bg-grey" *ngIf="unsubscribed">
        <div class="container">
            <div class="row">
                <div class="col text-center my-5 py-5">
                    <h1>You are now unsubscribed and have been removed from the program.</h1>
                </div>
            </div>
        </div>
    </section>

    <p class="my-5 py-4">&nbsp;</p>
</div>